'use client'

import {
	type MutableRefObject,
	type ReactNode,
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from 'react'
import { applyStylesIf, cx } from 'utils/cx'
import {
	hideOverflows,
	restoreOverflows,
} from 'utils/overflowControl/overflowControl'

import { ModalHeader } from './ModalHeader/ModalHeader'
import {
	CLOSING_ANIMATION_DURATION,
	isAnimatedType,
} from './helpers/animations'
import { getRole } from './helpers/getRole'
import { useModalListeners } from './hooks/useModalListeners'
import { useModalSize } from './hooks/useModalSize'
import { type ModalAPI, type ModalProps } from './types'

import styles from './Modal.module.scss'

export const Modal = forwardRef<ModalAPI, ModalProps>(function Modal(
	{
		children,
		className = '',
		'data-testid': dataTestId = 'modal',
		name = 'modal',
		type,
		title,
		subtitle,
		showTitle,
		footer,
		onClose,
		preventClose = false,
		keepContent = false,
	},
	ref
): ReactNode | null {
	const [shouldRenderChildren, setShouldRenderChildren] =
		useState<boolean>(false)
	const [animate, setAnimate] = useState<boolean>(false)
	const dialogRef: MutableRefObject<HTMLDialogElement | null> = useRef(null)
	const lastFocusedElement: MutableRefObject<HTMLElement | null> = useRef(null)
	const currentType = useModalSize(type)
	const initialized = useModalListeners({
		dialogRef,
		preventClose,
		close,
	})

	// Exposed API method to show the modal.

	function show() {
		if (!initialized) {
			return
		}

		setShouldRenderChildren(true)
		lastFocusedElement.current = document.activeElement as HTMLButtonElement
		dialogRef.current?.showModal()
		hideOverflows()

		// add css class to html tag to hide scrollbar
		document?.documentElement.classList.add(styles.bodyModalOpen)

		if (isAnimatedType(currentType)) {
			setAnimate(true)
		}
	}

	function onCloseHandler() {
		setShouldRenderChildren(keepContent)
		lastFocusedElement.current?.focus()
		dialogRef.current?.close?.()
		onClose?.()
	}

	// Exposed API method to close the modal.
	// This controls the time when the closing functions must be called.
	function close() {
		if (isAnimatedType(currentType)) {
			setAnimate(false)
			setTimeout(() => {
				onCloseHandler()
			}, CLOSING_ANIMATION_DURATION)
		} else {
			onCloseHandler()
		}

		restoreOverflows()
		// remove css class to html tag to hide scrollbar
		document?.documentElement.classList.remove(styles.bodyModalOpen)
	}

	/** Exposes a modal API. */
	useImperativeHandle(
		ref,
		() =>
			({
				show,
				close,
				isOpen: !!dialogRef.current?.open,
			}) satisfies ModalAPI,
		[]
	)

	return (
		<dialog
			id={name}
			className={cx(
				styles[`modalType${currentType}`],
				styles.dialog,
				applyStylesIf(animate, styles.animate)
			)}
			aria-label={shouldRenderChildren ? title : undefined}
			ref={dialogRef}
			data-testid={dataTestId}
			role={getRole(preventClose)}
		>
			{shouldRenderChildren && (
				<div className={styles.modal}>
					<ModalHeader
						className={styles.header}
						title={title}
						subtitle={subtitle}
						preventClose={preventClose}
						close={close}
						showTitle={showTitle}
						type={currentType}
					/>
					<div className={cx(styles.modalContent, className)}>
						{/** A11Y NOTE: children MUST have an <h1> element */}
						{children}
					</div>
					<div className={styles.footer}>
						{footer && <div className={styles.footerContent}>{footer}</div>}
					</div>
				</div>
			)}
		</dialog>
	)
})
