'use client'

import type { LabelsContextType } from 'labels/types'
import { createContext, useCallback, useMemo, useState } from 'react'
import type { Labels } from 'types/labels'

export const LayoutStateLabelsContext = createContext<
	LabelsContextType | undefined
>(undefined)
export const LayoutSetStateLabelsContext = createContext<
	(newLabels: Labels) => void
>(() => {
	/* do nothing */
})

export function LayoutLabelsProvider({
	children,
	labels = {},
}: {
	children: React.ReactNode
	labels: Labels | undefined
}) {
	const [state, setState] = useState<Labels>()
	const layoutAndPageLabels = useMemo(
		() => ({ ...labels, ...state }),
		[labels, state]
	)
	const setLabels = useCallback((newLabels: Labels) => {
		setState((prev) => ({ ...prev, ...newLabels }))
	}, [])

	const value = useMemo(
		() => ({
			t: (key: string) => layoutAndPageLabels[key] ?? key,
			labels: layoutAndPageLabels,
		}),
		[state]
	)

	return (
		<LayoutStateLabelsContext.Provider value={value}>
			<LayoutSetStateLabelsContext.Provider value={setLabels}>
				{children}
			</LayoutSetStateLabelsContext.Provider>
		</LayoutStateLabelsContext.Provider>
	)
}
